const Error = ({errorMessage}: {errorMessage: string | null}) => {
  if (errorMessage == null) return <div role="alert"></div>;
  if (errorMessage.length > 0) {
    return <div role="alert">Error: {errorMessage}</div>;
  } else {
    return <div role="alert"></div>;
  }
};

export default Error;
