import { useContext, createContext, useState, useMemo } from 'react';

import jwt from 'jsonwebtoken';

export type AuthToken = {
  expirationDate: Date;
  userId: string;
  name: string;
};

export type AuthContextType = {
  authToken: AuthToken | null;
  encodedToken: string | null;
  setEncodedToken: (token: string) => void;
};

const AuthContext = createContext<AuthContextType>({
  authToken: null,
  encodedToken: null,
  setEncodedToken: (token: string) => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const ProvideAuth = (props: any) => {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
};

function useProvideAuth() {
  const [encodedToken, setEncodedToken] = useState<string | null>(null);
  const authToken = useMemo(
    () =>
      encodedToken === null ? null : (jwt.decode(encodedToken) as AuthToken),
    [encodedToken],
  );

  return {
    authToken,
    encodedToken,
    setEncodedToken,
  };
}
