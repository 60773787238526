import {BurgerNav} from './BurgerNav';
import {ListNav} from './ListNav';

export interface NavMenuItem {
  title: string;
  link: string;
}

export function TopNavButtons() {
  const menuItems: NavMenuItem[] = [
    {title: 'Library', link: '/library'},
    {title: 'Discover', link: '/discover'},
    {title: 'Community', link: '/community'},
  ];

  return (
    <>
      <ListNav menuItems={menuItems} />
      <BurgerNav menuItems={menuItems} />
    </>
  );
}
