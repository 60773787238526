import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import {Link} from 'react-router-dom';
import {
  Greyscale30,
  Greyscale50,
  Greyscale60,
  Greyscale70,
} from '../../../../../styles/Colors';
import {NavMenuItem} from './TopNav';

const BurgerContainer = styled.div`
  order: -1;
  @media (min-width: 900px) {
    display: none;
  }
`;

const StyledBurger = styled.button<Props>`
  width: 2rem;
  height: 2rem;
  display: flex;

  flex-direction: column;
  justify-content: space-around;

  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5;
  z-index: 10;

  margin-right: 24px;
  margin-left: 12px;

  :focus {
    outline: none;
  }

  @media (min-width: 900px) {
    display: none;
  }

  :hover {
    div {
      background: ${Greyscale50};
    }
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${Greyscale30};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({open}) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({open}) => (open ? '0' : '1')};
      transform: ${({open}) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({open}) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

interface Props {
  open: boolean;
}

const StyledBurgerMenu = styled.div<Props>`
  display: flex;
  flex-direction: column;
  background-color: ${Greyscale60};
  z-index: 0;
  text-align: right;
  padding-top: 50px;

  height: 100vh;

  width: 400px;

  position: absolute;
  top: 0;
  left: 0;

  transition: transform 0.3s ease-in-out;

  @media (min-width: 900px) {
    display: none;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  transform: ${({open}) => (open ? 'translateX(0)' : 'translateX(-100%)')};

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 1.5rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    text-decoration: none;
    transition: color 0.3s linear;
    color: ${Greyscale30};

    :hover {
      color: ${Greyscale70};
    }
  }
`;

interface PropTypes {
  menuItems: NavMenuItem[];
}

const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: {target: any}) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

export function BurgerNav(props: PropTypes) {
  const [menuOpen, setMenuOpen] = useState(false);

  const burgerItems = props.menuItems.map(item => (
    <Link key={item.title} to={item.link}>
      {item.title}
    </Link>
  ));

  const ref: any = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));

  return (
    <BurgerContainer ref={ref}>
      <StyledBurgerMenu open={menuOpen}>{burgerItems}</StyledBurgerMenu>
      <StyledBurger
        open={menuOpen}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
    </BurgerContainer>
  );
}
