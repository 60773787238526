import styled from '@emotion/styled';
import * as React from 'react';
import {Sidebar} from './navigation/Sidebar/Sidebar';
import {TopBar} from './navigation/TopBar/TopBar';

type LayoutProps = {
  children?: React.ReactNode;
  headerChildren?: React.ReactNode;
};

const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: 256px auto minmax(256px, 512px) auto 256px;
  grid-template-rows: repeat(2, auto);
  grid-gap: 1em;

  grid-template-areas:
    'header header header header header'
    'empty1 sidebar1 body sidebar2 empty2';

  @media (max-width: 900px) {
    grid-template-columns: auto;
    grid-template-areas:
      'header'
      'body';
  }
`;

const HeaderContainer = styled.div`
  grid-area: header;
`;

const SidebarContainer = styled.div`
  grid-area: sidebar1;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Sidebar2Container = styled.div`
  grid-area: sidebar2;
  @media (max-width: 900px) {
    display: none;
  }
`;

const BodyContent = styled.div`
  grid-area: body;
`;

export function Layout(props: LayoutProps) {
  return (
    <StyledLayout>
      <HeaderContainer>
        <TopBar />
        {props.headerChildren}
      </HeaderContainer>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <BodyContent>{props.children}</BodyContent>
      <Sidebar2Container>
        <Sidebar />
      </Sidebar2Container>
    </StyledLayout>
  );
}
