import Button from '../../../shared/components/button';
import {SignupForm} from '../SignupForm/SignupForm';

export function SignupPage() {
  return (
    <div>
      <Button>Sign up with Facebook</Button>
      <SignupForm />
    </div>
  );
}
