import {gql, useMutation} from '@apollo/client';
import { useState } from 'react';
import {Redirect} from 'react-router-dom';
import Button from '../../../shared/components/button';
import Error from '../../../shared/components/error';
import Form from '../../../shared/components/form';
import {useAuth} from '../../../shared/hooks/useAuth';
import useForm from '../../../shared/hooks/useForm';
import {Login, LoginVariables} from './__generated__/Login';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      __typename
      ... on LoginSuccess {
        authToken
      }
      ... on InvalidEmailOrPassword {
        message
      }
    }
  }
`;

function useLoginComponent(
  initialState = {
    email: '',
    password: '',
  },
) {
  const {inputs, handleChange} = useForm(initialState);
  const [error, setError] = useState('');
  const [emailError] = useState('');
  const [success, setSuccess] = useState(false);
  const {setEncodedToken} = useAuth();

  function validateInputs(): boolean {
    if (inputs.email === '') {
      setError('Email Cannot be Empty');
      return false;
    }
    if (inputs.password === '') {
      setError('Password Cannot be Empty');
      return false;
    }
    return true;
  }

  const [login, {data, loading}] = useMutation<Login, LoginVariables>(
    LOGIN_MUTATION,
    {
      onCompleted: data => {
        if (data.login.__typename === 'InvalidEmailOrPassword') {
          setError(data.login.message || 'Invalid Email Or Password');
        } else if (data.login.__typename === 'LoginSuccess') {
          if (data.login.authToken !== null) {
            setSuccess(true);
            setEncodedToken(data.login.authToken);
          }
        }
      },
    },
  );

  function onSubmit() {
    if (!validateInputs()) {
      return;
    }
    login({
      variables: {
        email: inputs.email,
        password: inputs.password,
      },
    });
  }

  return {
    models: {inputs, emailError, error, loading, data, success},
    operations: {handleChange, onSubmit},
  };
}

const LoginForm = () => {
  const [test, setTest] = useState(false);
  const {models, operations} = useLoginComponent();
  const {inputs, error, loading, success} = models;
  const {handleChange, onSubmit} = operations;

  if (test) {
    setTest(false);
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    onSubmit();
  }

  if (success) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <div>Submitting...</div>;
  }

  return (
    <Form method="post" onSubmit={handleSubmit}>
      <fieldset>
        <EmailField email={inputs.email} handleChange={handleChange} />
        <PasswordField password={inputs.password} handleChange={handleChange} />

        <Button type="submit">Login</Button>
      </fieldset>

      <Error errorMessage={error} />
    </Form>
  );
};

const EmailField = (props: any) => {
  return (
    <>
      <label htmlFor="email">Email</label>
      <input
        id="email"
        type="email"
        name="email"
        placeholder="Your email address"
        autoComplete="email"
        value={props.email}
        onChange={props.handleChange}
      />
    </>
  );
};

const PasswordField = (props: any) => {
  return (
    <>
      <label htmlFor="password">Password</label>
      <input
        id="password"
        type="password"
        name="password"
        placeholder="Password"
        autoComplete="password"
        value={props.password}
        onChange={props.handleChange}
      />
    </>
  );
};

export {LoginForm, useLoginComponent};
