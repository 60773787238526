import { useState } from 'react';
import * as React from 'react';

export default function useForm<T>(initial: T) {
  const [inputs, setInputs] = useState(initial);
  const initialTouched = Object.entries(initial).map(([key, value]) => [
    key,
    false,
  ]);
  const [touched, setTouched] = useState(Object.fromEntries(initialTouched));

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    let {value, name, type} = e.target;

    let formValue;
    if (type === 'number') {
      formValue = parseInt(value);
    } else if (type === 'file') {
      formValue = [];
      formValue[0] = e.target.files;
    } else {
      formValue = value;
    }

    setInputs({
      ...inputs,
      [name]: formValue,
    });
  }

  function onBlur(e: React.FocusEvent<HTMLInputElement>) {
    let {name} = e.target;
    setTouched({
      ...touched,
      [name]: true,
    });
  }

  function resetForm() {
    setInputs(initial);
  }

  function clearForm() {
    const blankState = Object.entries(inputs).map(([key]) => [key, '']);
    setInputs(Object.fromEntries(blankState));
  }

  return {inputs, touched, handleChange, onBlur, resetForm, clearForm};
}
