import {gql, useMutation} from '@apollo/client';
import { useState } from 'react';
import Button from '../../../shared/components/button';
import Error from '../../../shared/components/error';
import Form from '../../../shared/components/form';
import useForm from '../../../shared/hooks/useForm';
import {AddPodcast, AddPodcastVariables} from './__generated__/AddPodcast';

const ADD_PODCAST_MUTATION = gql`
  mutation AddPodcast(
    $name: String!
    $url: String!
    $podcasterNames: [String!]!
  ) {
    createPodcast(name: $name, rssUrl: $url, podcasterNames: $podcasterNames) {
      __typename
      ... on CreatePodcastSuccess {
        podcast {
          name
        }
      }
      ... on InvalidPodcastUrl {
        requiredUrlFormat
      }
    }
  }
`;

function useAddPodcastComponent(
  initialState = {
    name: '',
    url: '',
    podcasters: '',
  },
) {
  const {inputs, handleChange} = useForm(initialState);
  const [error, setError] = useState('');
  const [emailError] = useState('');
  const [success, setSuccess] = useState(false);

  function validateInputs(): boolean {
    return true;
  }

  const [addPodcast, {data, loading}] = useMutation<
    AddPodcast,
    AddPodcastVariables
  >(ADD_PODCAST_MUTATION, {
    onCompleted: data => {
      if (data.createPodcast.__typename === 'InvalidPodcastUrl') {
        setError('Invalid url');
      } else if (data.createPodcast.__typename === 'CreatePodcastSuccess') {
        setSuccess(true);
      }
    },
  });

  function onSubmit() {
    if (!validateInputs()) {
      return;
    }
    addPodcast({
      variables: {
        name: inputs.name,
        url: inputs.url,
        podcasterNames: inputs.podcasters.split(',').map(v => v.trim()),
      },
    });
  }

  return {
    models: {inputs, emailError, error, loading, data, success},
    operations: {handleChange, onSubmit},
  };
}

const AddPodcastForm = () => {
  const {models, operations} = useAddPodcastComponent();
  const {inputs, error, loading, success} = models;
  const {handleChange, onSubmit} = operations;

  function handleSubmit(e: any) {
    e.preventDefault();
    onSubmit();
  }

  if (success) {
    return <div>Success!</div>;
  }

  if (loading) {
    return <div>Submitting...</div>;
  }

  return (
    <Form method="post" onSubmit={handleSubmit}>
      <fieldset>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          name="name"
          placeholder="Podcast name"
          value={inputs.name}
          onChange={handleChange}
        />
        <label htmlFor="url">RSS Feed Url</label>
        <input
          id="url"
          type="text"
          name="url"
          placeholder="Rss Url"
          value={inputs.url}
          onChange={handleChange}
        />
        <label htmlFor="podcasters">Podcasters (comma separated)</label>
        <input
          id="podcasters"
          type="text"
          name="podcasters"
          placeholder="Podcaster Names"
          value={inputs.podcasters}
          onChange={handleChange}
        />

        <Button type="submit">Add</Button>
      </fieldset>

      <Error errorMessage={error} />
    </Form>
  );
};

export {AddPodcastForm, useAddPodcastComponent};
