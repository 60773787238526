import React from 'react';
import {Layout} from '../Layout';
import {AdminNavbar} from './AdminNavbar';

type LayoutProps = {
  children?: React.ReactNode;
};

export function AdminLayout(props: LayoutProps) {
  return (
    <Layout headerChildren={<AdminNavbar />}>
      <div>{props.children}</div>
    </Layout>
  );
}
