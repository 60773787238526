import {Link} from 'react-router-dom';
import styled from '@emotion/styled';
import {Layout} from '../../../shared/components/layout/Layout';

const SignupLink = styled(Link)`
  color: red;
`;

export function UnauthenticatedHomePage() {
  return (
    <Layout>
      <SignupLink to="/signup">Sign Up</SignupLink>
      <SignupLink to="/login">Log In</SignupLink>
    </Layout>
  );
}
