import styled from '@emotion/styled';

const StyledProfileButton = styled('button')`
  width: 48px;
  height: 48px;
  border-radius: 10px;
  border: none;
  background-color: gray;
  margin: 10px;
  flex-shrink: 0;
  flex-grow: 0;

  @media (max-width: 900px) {
    display: none;
  }
`;

export function ProfileButton() {
  return <StyledProfileButton />;
}
