import {gql, useMutation} from '@apollo/client';
import { useState } from 'react';
import {Redirect} from 'react-router-dom';
import Button from '../../../shared/components/button';
import Form from '../../../shared/components/form';
import {InputField} from '../../../shared/components/InputField';
import useForm from '../../../shared/hooks/useForm';
import {Signup, SignupVariables} from './__generated__/Signup';

const SIGNUP_MUTATION = gql`
  mutation Signup(
    $name: String!
    $email: String!
    $password: String!
    $dateOfBirth: String!
  ) {
    signup(
      name: $name
      email: $email
      password: $password
      dateOfBirth: $dateOfBirth
    ) {
      ... on SignupSuccess {
        user {
          name
        }
      }
    }
  }
`;

function useSignupComponent(
  initialState = {
    email: '',
    password: '',
    name: '',
    dateOfBirth: '',
  },
) {
  const {inputs, touched, handleChange, onBlur} = useForm(initialState);
  const [emailError] = useState('');

  const [signup, {data, loading}] = useMutation<Signup, SignupVariables>(
    SIGNUP_MUTATION,
  );

  function onSubmit() {
    if (!validateInputs(inputs)) {
      return;
    }
    signup({
      variables: {
        email: inputs.email,
        name: inputs.name,
        password: inputs.password,
        dateOfBirth: inputs.dateOfBirth,
      },
    });
  }

  return {
    models: {inputs, touched, emailError, loading, data},
    operations: {handleChange, onSubmit, onBlur},
  };
}

function validateInputs(inputs: {
  email: string;
  name: string;
  password: string;
  dateOfBirth: string;
}) {
  let emailError = undefined;
  let nameError = undefined;
  let passwordError = undefined;
  let dobError = undefined;

  if (inputs.email === '') {
    emailError = 'Email Cannot be Empty';
  } else if (inputs.email.indexOf('@') === -1) {
    emailError = "Invalid Email, must contain '@'";
  }

  if (inputs.name === '') {
    nameError = 'Name Cannot be Empty';
  }

  if (inputs.password === '') {
    passwordError = 'Password Cannot be Empty';
  } else if (inputs.password.length < 8) {
    passwordError = 'Password must be at least 8 characters long';
  } else if (!inputs.password.match(/\d+/g)) {
    passwordError = 'Password must have at least one number';
  } else if (inputs.password.toLowerCase() === inputs.password) {
    passwordError = 'Password must have at least one uppercase letter';
  } else if (inputs.password.toUpperCase() === inputs.password) {
    passwordError = 'Password must have at least one lowercase letter';
  }

  return {
    emailError,
    nameError,
    passwordError,
    dobError,
  };
}

const SignupForm = () => {
  const {models, operations} = useSignupComponent();
  const {inputs, touched, loading, data} = models;
  const {handleChange, onBlur, onSubmit} = operations;

  function handleSubmit(e: any) {
    e.preventDefault();
    onSubmit();
  }

  let validationResult = validateInputs(inputs);

  if (loading) {
    return <div>Submitting...</div>;
  }

  if (data && data.signup.__typename === 'SignupSuccess') {
    return <Redirect to="/verify" />;
  }

  return (
    <Form method="post" onSubmit={handleSubmit}>
      <fieldset>
        <InputField
          label="Name"
          id="name"
          type="text"
          placeholder="Name"
          autoComplete="name"
          error={touched['name'] ? validationResult.nameError : undefined}
          value={inputs.name}
          onBlur={onBlur}
          onChange={handleChange}
        />

        <InputField
          label="Email"
          id="email"
          type="email"
          placeholder="Your Email"
          autoComplete="email"
          error={touched['email'] ? validationResult.emailError : undefined}
          value={inputs.email}
          onBlur={onBlur}
          onChange={handleChange}
        />

        <InputField
          label="Password"
          id="password"
          type="password"
          placeholder="Password"
          autoComplete="password"
          error={
            touched['password'] ? validationResult.passwordError : undefined
          }
          value={inputs.password}
          onBlur={onBlur}
          onChange={handleChange}
        />

        <InputField
          label="Date of Birth"
          id="dateOfBirth"
          type="date"
          placeholder="Date Of Birth"
          error={touched['dateOfBirth'] ? validationResult.dobError : undefined}
          value={inputs.dateOfBirth}
          onBlur={onBlur}
          onChange={handleChange}
        />

        <Button type="submit">Signup</Button>
      </fieldset>
    </Form>
  );
};

export {SignupForm, useSignupComponent};
