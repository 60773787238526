import styled from '@emotion/styled';
import {TopSearch} from './TopSearch';
import {TopNavButtons} from './Navigation/TopNav';
import {White} from '../../../../styles/Colors';
import {ProfileButton} from './Profile/ProfileButton';
import {Logo} from '../Logo';
import {AdminNav} from './Navigation/AdminNav';
import {NotificationBell} from './Notifications/NotificationBell';

const StyledTopBar = styled.div`
  height: 48px;
  width: 100%;
  background-color: ${White};
  display: flex;
  flex-direction: row;
  padding-bottom: 1px;
  justify-content: center;
  padding: 6px 12px;
  margin-bottom: 10px;
`;

const TopBarContents = styled.div`
  width: 100%;
  height: 48px;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopBarLeftSide = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TopBarRightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export function TopBar() {
  return (
    <StyledTopBar>
      <TopBarContents>
        <TopBarLeftSide>
          <Logo />
          <TopSearch />
        </TopBarLeftSide>
        <TopBarRightSide>
          <AdminNav />
          <TopNavButtons />
          <NotificationBell />
          <ProfileButton />
        </TopBarRightSide>
      </TopBarContents>
    </StyledTopBar>
  );
}
