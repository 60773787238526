import {Layout} from '../../../shared/components/layout/Layout';
import {BrowseGenres} from '../BrowseGenres/BrowseGenres';
import {PopularShows} from '../PopularShows/PopularShows';
import {Suggestions} from '../Suggestions/Suggestions';

export function DiscoverPage() {
  return (
    <Layout>
      <PopularShows />
      <Suggestions />
      <BrowseGenres />
    </Layout>
  );
}
