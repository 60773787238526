import {css} from '@emotion/react';
import {Background, TextOnBackground} from './Colors';

const GlobalStyles = css`
  body {
    font-family: 'Montserrat', 'Gill Sans', 'Gill Sans MT', Calibri,
      'Trebuchet MS', sans-serif;
    font-size: 20px;
    color: ${TextOnBackground};
    background-color: ${Background};
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

export default GlobalStyles;
