import AuthenticatedApp from './shared/app/AuthenticatedApp';
import UnauthenticatedApp from './shared/app/UnauthenticatedApp';
import {useAuth} from './shared/hooks/useAuth';

import "@fontsource/montserrat";
import "@fontsource/montserrat/600.css";
import "@fontsource/karla";

function App() {
  const {authToken} = useAuth();
  if (authToken !== null) {
    return <AuthenticatedApp />;
  } else {
    return <UnauthenticatedApp />;
  }
}

export default App;
