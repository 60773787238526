import {Route, Switch} from 'react-router-dom';
import {
  UnauthenticatedHomePage,
  // HomePage,
  LoginPage,
  SignupPage,
  VerifiedPage,
  VerifyPage,
} from '../../pages';
import {AddPodcastPage} from '../../pages/AddPodcast/AddPodcastPage/AddPodcastPage';
import {DiscoverPage} from '../../pages/Discover/DiscoverPage/DiscoverPage';

function UnauthenticatedApp() {
  return (
    <Switch>
      <Route path="/verify-email">
        <VerifiedPage />
      </Route>
      <Route path="/verify">
        <VerifyPage />
      </Route>
      <Route path="/signup">
        <SignupPage />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/discover">
        <DiscoverPage />
      </Route>
      <Route path="/add-podcast">
        <AddPodcastPage />
      </Route>
      <Route path="/">
        <UnauthenticatedHomePage />
      </Route>
    </Switch>
  );
}

export default UnauthenticatedApp;
