import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

const LogoLink = styled(Link)`
  height: 48px;
  display: block;
  padding: 4px;
  margin: 0;
  margin-bottom: 1px;
  margin-right: 30px;
  flex-grow: 0;
  flex-shrink: 0;

  :hover {
    background-color: lightgrey;
  }
`;

const LogoImg = styled.img`
  height: 40px;

  @media (max-width: 900px) {
    display: none;
  }
`;

const LogoImgMobile = styled.img`
  height: 40px;
  @media (min-width: 900px) {
    display: none;
  }
`;

export function Logo() {
  return (
    <LogoLink to="/">
      <LogoImg src="Logo.svg" alt="Logo" />
      <LogoImgMobile src="Logo.svg" />
    </LogoLink>
  );
}
