import { useContext, createContext } from 'react';
import {gql, useQuery} from '@apollo/client';
import {GetAuthToken} from './__generated__/GetAuthToken';
import {useAuth} from './useAuth';

type LoginContextType = {
  signin: () => void;
};

const LoginContext = createContext<LoginContextType>({
  signin: () => {},
});

export const useLogin = () => {
  return useContext(LoginContext);
};

export const ProvideLogin = (props: any) => {
  const login = useProvideLogin();
  return (
    <LoginContext.Provider value={login}>
      {props.children}
    </LoginContext.Provider>
  );
};

const GET_AUTH_TOKEN = gql`
  query GetAuthToken {
    getAuthToken {
      ... on AuthToken {
        token
      }
      ... on NoRefreshToken {
        message
      }
      ... on InvalidRefreshToken {
        message
      }
      ... on ExpiredRefreshToken {
        message
      }
    }
  }
`;

function useProvideLogin() {
  let {setEncodedToken, authToken} = useAuth();
  const {refetch} = useQuery<GetAuthToken>(GET_AUTH_TOKEN, {
    skip: authToken != null,
    onCompleted: data => {
      let result = data.getAuthToken;
      if (result != null) {
        switch (result.__typename) {
          case 'AuthToken':
            setEncodedToken(result.token);
            break;
          case 'NoRefreshToken':
            break;
          case 'ExpiredRefreshToken':
            break;
          case 'InvalidRefreshToken':
            break;
          default:
            break;
        }
      }
    },
  });

  const signin = () => {
    refetch();
  };

  return {
    signin,
  };
}
