import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import {Greyscale30, Greyscale50} from '../../../../../styles/Colors';

const StyledAdminDropdown = styled(Link)`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: ${Greyscale30};
  border-bottom: 2px solid ${Greyscale50};
  padding: 0px 16px;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 900px) {
    display: none;
  }
`;

export function AdminNav() {
  return <StyledAdminDropdown to={'admin'}>Admin Tools</StyledAdminDropdown>;
}
