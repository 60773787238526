import * as React from 'react';

interface InputFieldProps {
  label: string;
  id: string;
  value: string;
  error?: string | undefined;
  type?: string | undefined;
  autoComplete?: string | undefined;
  placeholder?: string | undefined;
  onBlur: React.FocusEventHandler;
  onChange: React.ChangeEventHandler;
}

export function InputField(props: InputFieldProps) {
  return (
    <>
      <label htmlFor={props.id}>{props.label}</label>
      <input
        id={props.id}
        type={props.type}
        name={props.id}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        value={props.value}
        onBlur={props.onBlur}
        onChange={props.onChange}
      />
      {props.error ? <div>{props.error}</div> : <div />}
    </>
  );
}
