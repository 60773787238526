import styled from '@emotion/styled'
import React from 'react'
import { Greyscale70 } from '../../../styles/Colors'

const BarContainer = styled.div`
  height: 40px;
  background-color: ${Greyscale70};
  width: 100%;
`;

const NavList = styled.ul`
  width: 100%;
  height: 100%;
  overflow: hidden;
  list-style: none;
  vertical-align: middle;
  padding: 0 20px;
  margin: 0px;
  font-family: "karla";
`;

const NavItem = styled.li`
  line-height: 40px;

  a {
      color: black;
      text-decoration: none;
  }
`;

const NavItemLeft = styled(NavItem)`
  float: left;
`;

const NavItemRight = styled(NavItem)`
  float: right;
`

export function AdminNavbar() {
    return <BarContainer>
        <NavList>
            <NavItemLeft>
                <a href="/admin">Dashboard</a>
            </NavItemLeft>
            <NavItemRight>
                <a href="/addpodcast">Add Podcast</a>
            </NavItemRight>
        </NavList>
        Admin Nav
    </BarContainer>
}