import { useEffect } from 'react';

import {gql, useMutation} from '@apollo/client';
import {useLocation} from 'react-router-dom';

import {VerifyEmail, VerifyEmailVariables} from './__generated__/VerifyEmail';
import {useAuth} from '../shared/hooks/useAuth';

const VERIFY_MUTATION = gql`
  mutation VerifyEmail($code: String!, $email: String!) {
    verifyEmail(code: $code, email: $email) {
      authToken
    }
  }
`;
function useUrlParams() {
  return new URLSearchParams(useLocation().search);
}

const VerifiedPage = () => {
  let params = useUrlParams();
  let email = params.get('email') || '';
  let code = params.get('code') || '';
  let auth = useAuth();

  const [verify, {loading, error}] = useMutation<
    VerifyEmail,
    VerifyEmailVariables
  >(VERIFY_MUTATION, {
    onCompleted: ({verifyEmail}) => {
      if (verifyEmail.authToken) {
        auth.setEncodedToken(verifyEmail.authToken);
      }
    },
  });
  useEffect(() => {
    verify({variables: {email: email, code: code}});
  }, [code, email, verify]);

  if (loading) {
    return <div>Verifying...</div>;
  }

  if (error) {
    return <div>Failed to verify your account</div>;
  }

  return (
    <div>Thank you for verifying your account ${auth.authToken?.userId}</div>
  );
};

export {VerifiedPage};
