import styled from '@emotion/styled';
import {Greyscale30, Greyscale90} from '../../../../../styles/Colors';
import {NavMenuItem} from './TopNav';
import {Link} from 'react-router-dom';

const StyledList = styled.ul`
  list-style-type: none;
  margin-right: 30px;
  flex-shrink: 0;

  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledListItem = styled.li`
  float: left;

  display: block;
  padding: 10px 16px;
  border-radius: 10px;

  :hover {
    background-color: ${Greyscale90};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: ${Greyscale30};
  flex-grow: 0;
  flex-shrink: 0;
`;

export function ListNav({menuItems}: {menuItems: NavMenuItem[]}) {
  const listItems = menuItems.map(item => (
    <StyledListItem key={item.title}>
      <StyledLink to={item.link}>{item.title}</StyledLink>
    </StyledListItem>
  ));

  return (
    <>
      <StyledList>{listItems}</StyledList>
    </>
  );
}
