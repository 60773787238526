import {Layout} from '../../../shared/components/layout/Layout';
import {AddPodcastForm} from '../AddPodcastForm/AddPodcastForm';

export function AddPodcastPage() {
  return (
    <Layout>
      <AddPodcastForm />
    </Layout>
  );
}
