import {Route, Switch} from 'react-router-dom';
import {
  AuthenticatedHomePage,
  LoginPage,
  SignupPage,
  VerifiedPage,
  VerifyPage,
} from '../../pages';
import {AddPodcastPage} from '../../pages/AddPodcast/AddPodcastPage/AddPodcastPage';
import { AdminDashboard } from '../../pages/Admin/AdminDashboard/AdminDashboard';
import {DiscoverPage} from '../../pages/Discover/DiscoverPage/DiscoverPage';

function AuthenticatedApp()  {
  return (
    <Switch>
      <Route path="/verify-email">
        <VerifiedPage />
      </Route>
      <Route path="/verify">
        <VerifyPage />
      </Route>
      <Route path="/signup">
        <SignupPage />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/discover">
        <DiscoverPage />
      </Route>
      <Route path="/add-podcast">
        <AddPodcastPage />
      </Route>
      <Route path="/admin">
        <AdminDashboard />
      </Route>
      <Route path="/">
        <AuthenticatedHomePage />
      </Route>
    </Switch>
  );
}

export default AuthenticatedApp;
